










import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

// components
import CoursesMy from '@/components/views/courses/My.vue'
import CoursesSettings from '@/components/CoursesSettings.vue'

@Component({
  components: {
    CoursesMy,
    CoursesSettings,
  },
})
export default class MyCourses extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Уроки',
    }
  }
}
