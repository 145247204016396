




























import { Component, Prop, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterEducationModule from '@/store/modules/master/education'
import { VisibleCourseRequest, VisibleMasterGroupRequest } from '@/store/types'

export type VisibleMasterGroupForm = VisibleMasterGroupRequest & { title: string }

@Component({
  components: {
    Dialog,
    Draggable,
    ModalWrapper,
  },
})
export default class SettingsCoursesDashboardModal extends NotifyMixin {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private formCourses!: VisibleMasterGroupForm[]

  private get isDisabled() {
    return this.form.filter(course => course.isVisible).length === 1
  }

  private currentMasterGroupID = MasterEducationModule.currentMasterGroupID
  private form: VisibleMasterGroupForm[] = []

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private handleConfirm() {
    MasterEducationModule.updateMasterGroups(this.transformFormRequest())
      .then((response) => {
        this.notifySuccess('Отображение курсов успешно изменено.')
        this.handleClose()

        // Если был скрыт текущий курс на дашборде, то нужно запросить инфу дашборда о первом активном курсе
        const findGroup = response.find(group => group.id === this.currentMasterGroupID)
        this.$bus.$emit('changeCurrentIndexCourse', { fetchDashboard: !findGroup, masterGroupID: findGroup ? findGroup.id : response[0].id })

        // Обновляем список моих курсов
        this.$bus.$emit('updateMyCourses')
      })
      .catch(this.notifyError)
  }

  private transformFormRequest(): VisibleCourseRequest {
    return {
      courses: this.form.map((course, index) => ({
        courseId: course.courseId,
        isVisible: course.isVisible,
        position: index + 1,
      })),
    }
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      this.currentMasterGroupID = MasterEducationModule.currentMasterGroupID
      this.form = this.formCourses
    } else {
      this.form = []
    }
  }
}
